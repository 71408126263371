import queryString from 'query-string';

$(document).ready(() => {
  let $videoSrc;

  const videoId = queryString.parse(window.location.search).videoId;

  if (videoId) {
    $videoSrc = $(`#${videoId}`)
      .click()
      .attr('data-src');
  }

  $('.video-link').click(e => {
    $videoSrc = e.currentTarget.dataset.src;
  });
  // when the modal is opened autoplay it
  $('#tutorialModal').on('shown.bs.modal', () => {
    $('#video').attr(
      'src',
      `${$videoSrc}?autoplay=1&amp;modestbranding=1&amp;showinfo=0`
    );
  });
  // stop playing the youtube video when I close the modal
  $('.vertical-alignment-helper').on('click', () => {
    $videoSrc = null;
    $('#video').attr('src', $videoSrc);
    $('#tutorialModal').modal('hide');
  });
});

import 'bootstrap/dist/js/bootstrap.min';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery';
import cookies from 'js-cookie';
import * as Sentry from '@sentry/browser';
import setUpCaptcha from '../captcha';

$(document).ready(() => {
  Sentry.init({
    dsn:
      'https://47d6228a453346d38cefafcb43f465f0@o4504796840525824.ingest.sentry.io/4504796848586752',
    enabled: ENVIRONMENT === 'production',
    release: RELEASE,
  });
  setUpCaptcha();

  $('.multi-item-carousel').carousel({
    interval: false,
  });

  $('.multi-item-carousel .item').each(function() {
    const self = this;

    function getNext(self) {
      let next = $(self).next();
      if (!next.length) {
        next = $(self).siblings(':first');
      }
      return next;
    }

    const next = getNext(self);

    next
      .children(':first-child')
      .clone()
      .appendTo($(self));

    if (next.next().length > 0) {
      next
        .next()
        .children(':first-child')
        .clone()
        .appendTo($(self));
    } else {
      $(self)
        .siblings(':first')
        .children(':first-child')
        .clone()
        .appendTo($(self));
    }
  });

  $('.close').on('click', () => {
    cookies.set('close_cookies_banner', 'true');
    $('#cookies-banner').hide();
  });

  $('.expand').on('click', function() {
    const title = $(this).find('.panel-title');
    const sign = $(title).find('.pull-right');

    if (sign.text() == '+') {
      sign.text('-');
    } else {
      sign.text('+');
    }
  });

  const utmParams = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_content',
    'utm_term',
  ];

  const utmQuery = decodeURIComponent(window.location.search.substring(1));
  const utmVariables = utmQuery.split('&');

  const getUTMValue = inputParameter => {
    for (let i = 0; i < utmVariables.length; i++) {
      const ParameterName = utmVariables[i].split('=');
      if (ParameterName[0] === inputParameter) {
        return ParameterName[1];
      }
    }
  };
  utmParams.forEach(param => {
    const pValue = getUTMValue(param);
    if (param === 'utm_source' && pValue === 'producthunt') {
      $('#greetingPhBanner').modal('show');
    }
  });
});

export default function() {
  const GOOGLE_RECAPTCHA_SITE_KEY = '6Lf2fnQUAAAAAHxjQBL9g8V6ZjK1nK60sdw_BszK';
  const sitekey =
    NODE_ENV === 'e2e'
      ? '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
      : GOOGLE_RECAPTCHA_SITE_KEY;

  window.onloadCapcha = function() {
    $('.g-recaptcha').each(function() {
      const el = $(this);
      grecaptcha.render($(el).attr('id'), {
        sitekey,
        callback(token) {
          $(el)
            .closest('form')
            .find('.g-recaptcha-response')
            .val(token);
          $(el)
            .closest('form')
            .submit();
        },
      });
    });
  };

  $('#signUp').on('click', function(e) {
    if (
      $(this)
        .closest('form')[0]
        .checkValidity()
    ) {
      e.preventDefault();
      $('#captchaSignUp').trigger('click');
    }
  });
}
